import React, { createContext } from "react";
import Pool from "./UserPool";
import { Amplify, Auth } from "aws-amplify";
const {
  REACT_APP_AWS_AUTH_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_USER_POOL_CLIENT_ID,
} = process.env;

interface IAccountContext {
  getSession: () => Promise<any>;
  logout: () => void;
  getCurrentUser: () => any;
  updateUser: (key: string, value: string) => any;
  login: (Username: string, Password: string) => Promise<any>;
}
const AccountContext = createContext<IAccountContext>({
  getSession: () => Promise.resolve(),
  logout: () => {},
  getCurrentUser: () => {},
  updateUser: () => Promise.resolve(),
  login: () => Promise.resolve(),
});

const authConfig = {
  region: REACT_APP_AWS_AUTH_REGION,
  userPoolId: REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_AWS_USER_POOL_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: "USER_PASSWORD_AUTH",
};

Amplify.configure({
  Auth: authConfig,
});

const Account = (props: any) => {
  const getCurrentUser = () => {
    return Pool.getCurrentUser();
  };

  const getSession = async () => {
    return await new Promise(async (resolve, reject) => {
      const user = await Auth.currentUserPoolUser();
      if (user) {
        user.getSession((err: any, session: any) => {
          if (err) reject(err);
          else resolve(session);
        });
      }
    });
  };

  const login = (username: string, password: string) => {
    return Auth.signIn(username, password);
  };
  const logout = async () => {
    return Auth.signOut();
  };

  const updateUser = async (key: string, value: string) => {
    const user = await Auth.currentUserPoolUser({ bypassCache: true });
    const attribute = { [key]: value };
    return await new Promise((resolve, reject) => {
      Auth.updateUserAttributes(user, attribute)
        .then(() => resolve(attribute))
        .catch(() => reject("error"));
    });
  };
  return (
    <AccountContext.Provider
      value={{
        getSession,
        logout,
        getCurrentUser,
        updateUser,
        login,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
