import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "react-auth-kit";
import "./index.css";
import App from "./App";

const queryClient = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        authType="cookie"
        authName={"_auth"}
        cookieDomain={process.env.REACT_APP_COOKIE_DOMAIN}
        cookieSecure={true} // change it to true when using https
      >
        <App />
        <ReactQueryDevtools initialIsOpen={true} />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
