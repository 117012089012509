import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Avatar,
  Button,
} from "@mui/material";
import { Flex } from "shared/components";
import { refresh } from "shared/utils";
import MenuIcon from "@mui/icons-material/Menu";
import ProfileIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { useFetchUser } from "shared/hooks/user/useFetchUser";

const Header = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const signOut = useSignOut();
  const { id } = auth() || {};
  const logout = () => {
    signOut();
    refresh();
  };

  const { data: user } = useFetchUser();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const handleClose = () => {
    setAnchorEl(null);
    setShowMenu(false);
  };
  return (
    <Flex flexDirection="column" style={{ position: "sticky", top: 0 }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Button onClick={() => navigate("/sublet")} disableRipple>
          bedNDesk
        </Button>
        <Flex alignItems="center" onClick={() => navigate(`/sublet`)}></Flex>
        <Flex
          alignItems="center"
          border="1px solid grey"
          borderRadius="20px"
          onClick={(e: any) => {
            setShowMenu(!showMenu);
            setAnchorEl(e.currentTarget);
          }}
        >
          <IconButton style={{ gap: "0.5em" }} disableRipple={true}>
            <MenuIcon fontSize="small" />
            {user?.profileImgUrl ? (
              <Avatar
                alt="User Profile"
                src={user?.profileImgUrl}
                sx={{ width: "30px", height: "30px" }}
              />
            ) : (
              <ProfileIcon sx={{ width: "30px", height: "30px" }} />
            )}
            <Menu
              open={showMenu}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {!id && (
                <>
                  <MenuItem onClick={() => navigate("/register")}>
                    회원가입
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/login")}>로그인</MenuItem>
                </>
              )}
              {id && (
                <>
                  <MenuItem onClick={() => navigate("/account-settings")}>
                    계정 설정
                  </MenuItem>
                  <MenuItem onClick={logout}>로그아웃</MenuItem>
                </>
              )}
            </Menu>
          </IconButton>
        </Flex>
      </Flex>
      <Divider />
    </Flex>
  );
};

export default Header;
