import React from "react";
import styled from "styled-components";
import { Flex, Text, Image } from "shared/components";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import { testData } from "assets/constants";

const TitleHeader = styled(Text)`
  margin: 5px;
  font-size: 20px;
  font-weight: 500;
`;

const Card = styled(Flex)`
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  &:hover {
    cursor pointer;
    background-color: #f5f5f5;
  }
`;

export const IconContainer = styled(Flex)`
  margin: 0px 3px;
  align-items: center;
`;

const data = testData;
const Post = () => {
  const { id } = useParams();
  const postData = data.find(d => d.id === id);

  return (
    <Card>
      <Image
        src={postData?.photos[0]}
        width={150}
        height={120}
        borderRadius={5}
      />
      <Flex flexDirection="column">
        <TitleHeader>{postData?.title}</TitleHeader>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={swiper => console.log(swiper)}
        >
          <SwiperSlide>Slide 1</SwiperSlide>
          <SwiperSlide>Slide 2</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide>
          ...
        </Swiper>
      </Flex>
    </Card>
  );
};

export default Post;
