/* import API from "services"; */
import axios from "axios";
import { apiUrl } from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const generateSignedUrl = async (key: string) => {
  const result = await axios.post(`${apiUrl}/s3/signedUrl`, {
    key,
  });
  return result.data;
};
export const useGenerateSignedUrl = () => {
  const queryClient = useQueryClient();
  return useMutation(generateSignedUrl, {
    onSuccess: () => {
      queryClient.invalidateQueries(["images"]);
    },
  });
};
