/* import API from "services"; */
import axios from "axios";
import { apiUrl } from "config";
import { useQuery } from "@tanstack/react-query";

const getUser = async () => {
  const { data } = await axios.get(`${apiUrl}/user`, { withCredentials: true });
  console.log("DATA", data);
  return data?.user;
};
export const useFetchUser = () => {
  return useQuery(["user"], () => getUser(), {
    retry: false,
  });
};
