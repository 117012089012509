import React from "react";
import styled from "styled-components";
import { Flex, Text, Image, DetailText } from "shared/components";
import locationIcon from "assets/icons/location.png";
import viewIcon from "assets/icons/view.png";
import homeIcon from "assets/icons/home.png";
import dateIcon from "assets/icons/calendar.png";
import { useNavigate } from "react-router-dom";
const TitleHeader = styled(Text)`
  margin: 5px;
  font-size: 20px;
  font-weight: 500;
`;

const Card = styled(Flex)`
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  &:hover {
    cursor pointer;
    background-color: #f5f5f5;
  }
`;
const fallbackImage = "https://via.placeholder.com/150";

export const IconContainer = styled(Flex)`
  margin: 0px 3px;
  align-items: center;
`;

const PostCard = ({ data }: { data: any }) => {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() => {
        navigate(`/sublet/${data.id}`);
      }}
    >
      <Image
        src={data?.imageUrls?.[0] || fallbackImage}
        width={150}
        height={120}
        borderRadius={5}
      />
      <Flex flexDirection="column">
        <TitleHeader>{data.title}</TitleHeader>
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <IconContainer>
              <Image src={dateIcon} width={15} height={15} />
              <DetailText>{`${data.startDate} - ${data.endDate}`}</DetailText>
            </IconContainer>
            <IconContainer>
              <Image src={locationIcon} width={15} height={15} />
              <DetailText>{data.location}</DetailText>
            </IconContainer>
            <IconContainer>
              <Image src={homeIcon} width={15} height={15} />
              <DetailText>{data.roomType}</DetailText>
            </IconContainer>
            <IconContainer>
              <Image src={viewIcon} width={15} height={15} />
              <DetailText>{data.views}</DetailText>
            </IconContainer>
          </Flex>
          <TitleHeader>{`${data.currency || "$"}${data.price} / ${
            data.priceBy
          }`}</TitleHeader>
        </Flex>
      </Flex>
    </Card>
  );
};

export default PostCard;
