import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import { Account } from "Account";
import Header from "pages/Header";
import Sublet from "pages/Sublet";
import SignUp from "pages/SignUp";
import Login from "pages/Login";
import Post from "pages/sublet/Post";
import SubletUpload from "pages/sublet/Upload";
import AccountSettings from "pages/AccountSettings";

const App = () => {
  return (
    <Router>
      <Account>
        <Header />
        <Routes>
          <Route path="/sublet" element={<Sublet />} />
          <Route path="/sublet/upload" element={<SubletUpload />} />
          <Route path="/sublet/:id" element={<Post />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/account-settings"
            element={
              <RequireAuth loginPath="/login">
                <AccountSettings />
              </RequireAuth>
            }
          />
        </Routes>
      </Account>
    </Router>
  );
};

export default App;
