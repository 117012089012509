/* import API from "services"; */
import axios from "axios";
import { apiUrl } from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const createOrUpdatePost = async (post: any) => {
  const data = await axios.post(`${apiUrl}/sublet/post`, {
    ...post,
  });
  console.log("DATA", data);
  return data;
};
export const useMutatePost = () => {
  const queryClient = useQueryClient();
  return useMutation(createOrUpdatePost, {
    onSuccess: () => {
      queryClient.invalidateQueries(["posts"]);
    },
  });
};
