import axios from "axios";
import { apiUrl } from "config";
import { IUserSignIn, IUserSignUp, IUser } from "shared/types/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const signInUser = async (user: IUserSignIn) => {
  try {
    const data = await axios.post(`${apiUrl}/login`, {
      ...user,
    });
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error("회원정보가 옳바르지 않습니다");
  }
};

const createUser = async (user: IUserSignUp) => {
  try {
    const data = await axios.post(`${apiUrl}/register`, {
      ...user,
    });
    return data;
  } catch (error) {
    console.log("error", error);
    if ((error as any).response?.status === 409) {
      throw new Error(
        (error as any)?.response?.data?.message ||
          "이미 존재하는 아이디 입니다",
      );
    }
    throw new Error("회원가입에 실패했습니다");
  }
};

const updateUser = async (user: IUser) => {
  try {
    const data = await axios.put(`${apiUrl}/user/update`, {
      ...user,
    });
    return data;
  } catch (error) {
    throw new Error("Internal server error");
  }
};

export const useSignInUser = () => {
  const queryClient = useQueryClient();

  return useMutation(signInUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
    },
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
    },
  });
};
