/* import API from "services"; */
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const uploadImage = async ({ url, file }: { url: string; file: any }) => {
  try {
    const imgUrl = await axios.put(url, file);
    console.log("IMG URL", imgUrl);
    return imgUrl;
  } catch (err) {
    console.log("Error uploading image", err);
  }
};
export const useUploadImage = () => {
  const queryClient = useQueryClient();
  return useMutation(uploadImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["images"]);
    },
  });
};
