/* import API from "services"; */
import axios from "axios";
import { apiUrl } from "config";
import { useQuery } from "@tanstack/react-query";

const getPosts = async () => {
  const res = await axios.get(`${apiUrl}/sublet/posts`);
  console.log("DATA", res.data);
  return res.data;
};
export const useFetchPost = () => {
  return useQuery(["posts"], () => getPosts(), {
    retry: false,
  });
};
