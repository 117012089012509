import React from "react";
import { Flex } from "shared/components";
import { Select, MenuItem, CircularProgress } from "@mui/material";
import PostCard from "./sublet/post/Card";
import { useFetchPost } from "shared/hooks/sublet/useFetchPost";

const Sublet = () => {
  const [selectedRegion, setSelectedRegion] = React.useState<string>("다합");
  const { data: posts, isLoading } = useFetchPost();
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Select
        style={{ width: "200px" }}
        placeholder="가고 싶은 지역을 선택해주세요"
        value={selectedRegion}
        onChange={e => setSelectedRegion(e.target.value)}
      >
        {
          <MenuItem key={"다합"} value={"다합"}>
            {"다합"}
          </MenuItem>
        }
      </Select>
      <Flex flexDirection="column">
        {isLoading ? (
          <Flex width="100%" justifyContent="center">
            <CircularProgress color="secondary" />
          </Flex>
        ) : (
          posts?.map(d => <PostCard key={d.id} data={d} />)
        )}
      </Flex>
    </Flex>
  );
};

export default Sublet;
