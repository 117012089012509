import React, { useState, useContext } from "react";
import { Check, Clear, Edit } from "@mui/icons-material";
import { Alert, Input, IconButton, CircularProgress } from "@mui/material";
import { AccountContext } from "Account";

import { Flex } from "shared/components";
const InfoCard = ({
  title,
  id,
  value,
  disabled = false,
}: {
  title: string;
  id: string;
  value: string;
  disabled?: boolean;
}) => {
  const { updateUser } = useContext(AccountContext);
  const [edit, setEdit] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(value);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const handleSubmit = async () => {
    if (value !== newValue) {
      setIsUpdating(true);
      try {
        await updateUser(id, newValue);
        setIsUpdating(false);
      } catch (err) {
        setIsUpdating(false);
        console.log("EE", err);
        handleCancel();
        return <Alert severity="error">{"ERROR"}</Alert>;
      }
    }
    setEdit(false);
  };
  const handleCancel = async () => {
    console.log("??CNACEL");
    if (value !== newValue) {
      setNewValue(value);
    }
    setEdit(false);
  };

  return edit ? (
    <div>
      <Flex>
        <h3>{title}</h3>
      </Flex>
      <Flex>
        <Input
          value={newValue}
          disabled={isUpdating}
          onChange={e => setNewValue(e.target.value)}
          sx={{ width: "80%" }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleSubmit();
            } else if (e.key === "Escape") {
              handleCancel();
            }
          }}
        />
        {isUpdating && <CircularProgress size="1.5rem" />}
        {!isUpdating && (
          <IconButton onClick={handleSubmit}>
            <Check color="success" />
          </IconButton>
        )}
        {!isUpdating && (
          <IconButton onClick={handleCancel}>
            <Clear color="error" />
          </IconButton>
        )}
      </Flex>
    </div>
  ) : (
    <div>
      <Flex justifyContent="space-between">
        <h3>{title}</h3>
        {!disabled && (
          <IconButton onClick={() => setEdit(!edit)}>
            <Edit />
          </IconButton>
        )}
      </Flex>
      <Flex>
        <p>{newValue}</p>
      </Flex>
    </div>
  );
};

export default InfoCard;
