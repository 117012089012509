import React from "react";
import { Container, Flex } from "shared/components";
import InfoCard from "shared/components/accountSettings/InfoCard";
import ProfileCard from "shared/components/accountSettings/ProfileCard";
import Loader from "shared/components/Loader";
import { useFetchUser } from "shared/hooks/user/useFetchUser";

const AccountSettings = () => {
  // const [isUpdating, setIsUpdating] = React.useState(false); change it to context
  const { data: user } = useFetchUser();

  return (
    <Container>
      <Loader isLoading={!user} />
      {user && (
        <Flex flexDirection="column" width="600px" maxWidth="100%">
          <ProfileCard imgUrl={user?.profileImgUrl} />
          <InfoCard title="아이디" key="id" id="id" value={user.userId} disabled={true} />
          <InfoCard title="이름" key="name" id="name" value={user.name} />
          <InfoCard title="이메일" key="email" id="email" value={user.email} />
          <InfoCard
            title="전화번호"
            key="phone_number"
            id="phone_number"
            value={user.phoneNumber || "-"}
          />
        </Flex>
      )}
    </Container>
  );
};

export default AccountSettings;
