import React, { useState } from "react";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Avatar,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { message } from "antd";
import { Container, Flex } from "shared/components";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOpenOutlined from "@mui/icons-material/LockOpenOutlined";
import { useSignIn } from "react-auth-kit";
import { useSignInUser } from "shared/hooks/user/useMutateUser";
import { useNavigate } from "react-router-dom";
import Loader from "shared/components/Loader";

const DAY = 24 * 60 * 60 * 1000;
const Login = () => {
  const { mutate: login } = useSignInUser();
  const [userId, setUserId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const signIn = useSignIn();
  const navigate = useNavigate();

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    login(
      { userId, password },
      {
        onSuccess: result => {
          signIn({
            token: result.data.token,
            tokenType: "Bearer",
            authState: { id: result.data.userId },
            expiresIn: DAY,
          });
          setTimeout(() => {
            setIsLoading(false);
            navigate("/sublet");
          }, 2000);
        },
        onError: error => {
          message.error((error as any).message);
          setIsLoading(false);
        },
      },
    );
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container mt={3}>
      <Loader isLoading={isLoading} />
      <Box component="form" noValidate onSubmit={onSubmit}>
        <Flex flexDirection="column" maxWidth="100%" alignItems="center">
          <Avatar sx={{ m: 2, bgcolor: "royalblue" }}>
            <LockOpenOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            로그인
          </Typography>
          <Flex mt={5} mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={userId}
                  onChange={e => setUserId(e.target.value)}
                  variant="outlined"
                  label="아이디"
                  placeholder="아이디를 입력해주세요"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  label="비밀번호"
                  placeholder="비밀번호를 입력해주세요"
                  autoComplete="current-password"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Flex>
          <Button type="submit" onClick={onSubmit}>
            로그인
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};

export default Login;
