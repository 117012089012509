import React from "react";
import { TextField, Grid } from "@mui/material";
import { Controller } from "react-hook-form";

export const TextController = ({
  control,
  title,
  name,
}: {
  control: any;
  title?: string;
  name: string;
}) => {
  return (
    <Grid item xs={12}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            onChange={onChange}
            fullWidth
            label={title ?? name}
            variant="outlined"
          />
        )}
      />
    </Grid>
  );
};
