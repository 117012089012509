export const testData = [
  {
    id: "1",
    title:
      "맨하탄 배터리팍 (Downtown)/럭셔리 도어맨 빌딩 – 1 Bedroom / 2 Bedroom",
    currency: "$",
    price: "2400",
    priceBy: "월",
    location: "Manhattan",
    city: "New York",
    roomType: "1 Bedroom / 1 Bathroom",
    photos: ["https://picsum.photos/300", "https://picsum.photos/300"],
    postDate: "2023/01/03",
    startDate: "2023/01/23",
    endDate: "2023/02/03",
    views: 4,
    utility: "Included",
    deposit: "$1000",
    sex: "ALL",
  },
  {
    id: "2",
    title: "맨하탄 1 Bedroom / 2 Bedroom",
    currency: "$",
    price: "2400",
    priceBy: "월",
    location: "Manhattan",
    city: "Paris",
    roomType: "1 Bedroom / 1 Bathroom",
    photos: ["https://picsum.photos/300", "https://picsum.photos/300"],
    postDate: "2023/01/03",
    startDate: "2023/01/23",
    endDate: "2023/02/03",
    views: 4,
    utility: "Included",
    deposit: "$1000",
    sex: "ALL",
  },
];
