import styled from "styled-components";
import {
  alignItems,
  AlignItemsProps,
  border,
  BorderProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  flexDirection,
  FlexDirectionProps,
  justifyContent,
  JustifyContentProps,
  layout,
  LayoutProps,
  height,
  HeightProps,
  width,
  WidthProps,
} from "styled-system";

type FlexProps = AlignItemsProps &
  ColorProps &
  FlexDirectionProps &
  JustifyContentProps &
  SpaceProps &
  BorderProps &
  LayoutProps &
  HeightProps &
  WidthProps;

export const Flex = styled.div<FlexProps>`
  display: flex;
  margin: 5px 10px;
  ${color}
  ${alignItems}
  ${justifyContent}
  ${layout}
  ${space}
  ${flexDirection}
  ${height}
  ${width}
  ${border}
`;

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SelectBox = styled.select`
  width: 100%;
  height: 3em;
  cursor: pointer;
  background: white;
  color: #c65179;
  font-size: 14px;
  border: 2px solid #29bfdb;
  outline: none;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const Header = styled.h1`
  padding: 0px 15px;
  font-family: Arial;
  font-size: 32px;
`;

export const Text = styled.p`
  margin: 10px;
  font-size: 16px;
  font-weight: 500;
`;

export const DetailText = styled.p`
  margin: 3px 5px;
  font-size: 13px;
  font-weight: 600;
  color: grey;
`;

export const Path = styled.div`
  pointer-events: all;
  &:hover {
    cursor: pointer;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #f47f2b;
  padding: 0px 15px;
  font-size: 14px;
  color: #c65179;
  border-radius: 5px;
`;

export const Image = styled.img<BorderProps>`
  ${height}
  ${width}
  ${border}
  &:hover {
    cursor: pointer;
  }
`;
