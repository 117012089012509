import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import { message } from "antd";
import Check from "@mui/icons-material/Check";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import { Container, Flex } from "shared/components";
import { useCreateUser } from "shared/hooks/user/useMutateUser";
import { useNavigate } from "react-router-dom";
import Loader from "shared/components/Loader";

const SignUp = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [validUserId, setValidUserId] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate: createUser } = useCreateUser();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (password.length < 8) {
      return message.error("비밀번호는 8자리 이상이어야 합니다");
    }
    setIsLoading(true);
    const data = {
      userId,
      email,
      password,
    };
    createUser(data, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      },
      onError: error => {
        message.error((error as any).message);
        setIsLoading(false);
      },
    });
  };
  return (
    <Container mt={3}>
      <Loader isLoading={isLoading} />
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          회원가입이 완료되었습니다!
        </Alert>
      </Snackbar>
      <Box component="form" noValidate onSubmit={onSubmit}>
        <Flex flexDirection="column" maxWidth="100%" alignItems="center">
          <Avatar sx={{ m: 2, bgcolor: "royalblue" }}>
            <AccountCircleOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            회원가입
          </Typography>
          <Flex mt={5} mb={3} justifyContent="center">
            <Grid container xs={8} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  value={userId}
                  onChange={e => {
                    const text = e.target.value;
                    text.match(/^[a-z0-9]{8,}$/)
                      ? setValidUserId(true)
                      : setValidUserId(false);
                    setUserId(text);
                  }}
                  fullWidth
                  label="아이디"
                  placeholder="아이디를 입력해주세요"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {validUserId ? <Check color="success" /> : <Check />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={email}
                  onChange={e => {
                    const text = e.target.value;
                    text.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                      ? setValidEmail(true)
                      : setValidEmail(false);
                    setEmail(text);
                  }}
                  fullWidth
                  label="이메일"
                  placeholder="이메일을 입력해주세요"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {validEmail ? <Check color="success" /> : <Check />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  label="비밀번호"
                  placeholder="비밀번호를 입력해주세요"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Flex>
          <Button type="submit" onClick={onSubmit}>
            계정 생성하기
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};

export default SignUp;
