import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Snackbar,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useForm } from "react-hook-form";
import { Container, Flex, Image } from "shared/components";
import { useMutatePost } from "shared/hooks/sublet/useMutatePost";
import { useUploadImage } from "shared/hooks/sublet/useUploadImage";
import { useNavigate } from "react-router-dom";
import { Desktop } from "shared/hooks/MediaQuery";
import { TextController } from "shared/components/form";
import { generateSignedUrl } from "shared/hooks/sublet/useGenerateSignedUrl";
import { v4 as uuidv4 } from "uuid";

const Upload = () => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate: mutatePost } = useMutatePost();
  const { mutate: uploadImage } = useUploadImage();
  const { handleSubmit, control } = useForm();
  const onSelectFiles = (event: any) => {
    setSelectedFile(event.target.files[0]);
    console.log("F", event.target.files[0]);
  };
  const onSubmit = async (data: any) => {
    console.log("Data", data);
    setIsLoading(true);
    const today = new Date().toISOString().split("T")[0];
    // 여러 이미지 업로드 확인
    const key = `sublet/posts/${today}/${uuidv4()}`;
    const signedUrl = await generateSignedUrl(key);

    uploadImage(
      { url: signedUrl, file: selectedFile },
      {
        onSuccess: () => {
          console.log("CO");
        },
      },
    );
    const url = signedUrl.split("?")[0];
    mutatePost(
      { ...data, imageUrls: [url] },
      {
        onSuccess: () => {
          setOpenSnackbar(true);
          setTimeout(() => {
            setIsLoading(false);
            navigate("/sublet");
          }, 2000);
        },
      },
    );
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          매물이 등록되었습니다!
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Desktop>
          <Flex flexDirection="column" width="600px" maxWidth="100%">
            <Grid container spacing={2}>
              <TextController control={control} name={"title"} />
              <TextController control={control} name={"city"} />
              <TextController control={control} name={"location"} />
              <TextController control={control} name={"price"} />
              <TextController control={control} name={"priceBy"} />
              <TextController control={control} name={"description"} />
              <TextController control={control} name={"deposit"} />
              <TextController control={control} name={"startDate"} />
              <TextController control={control} name={"endDate"} />
              <Grid item xs={12}>
                {selectedFile && (
                  <Image width="200px" height="150px" src={preview} />
                )}
                <Button variant="contained" component="label">
                  <PhotoCamera />
                  사진 추가하기
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={onSelectFiles}
                  />
                </Button>
              </Grid>
            </Grid>

            <Button type="submit" onClick={onSubmit}>
              매물 등록하기
            </Button>
          </Flex>
        </Desktop>
      </form>
    </Container>
  );
};

export default Upload;
