import React from "react";
import { Avatar, Button } from "@mui/material";
import { Flex } from "shared/components";
import { refresh } from "shared/utils";
import DefaultProfile from "assets/icons/profile.png";
import { generateSignedUrl } from "shared/hooks/sublet/useGenerateSignedUrl";
import { useUploadImage } from "shared/hooks/sublet/useUploadImage";
import { useAuthUser } from "react-auth-kit";
import { useUpdateUser } from "shared/hooks/user/useMutateUser";

const ProfileCard = ({ imgUrl }: { imgUrl: string }) => {
  const auth = useAuthUser();
  const { id } = auth() || {};
  const { mutate: uploadImage } = useUploadImage();
  const { mutate: updateUser } = useUpdateUser();

  const handleProfileChange = async e => {
    const key = `users/profiles/${id}`;
    const signedUrl = await generateSignedUrl(key);
    const profileImgUrl = signedUrl.split("?")[0];
    const newProfileImage = e.target.files[0];
    const data = { userId: id, profileImgUrl };
    uploadImage(
      { url: signedUrl as unknown as string, file: newProfileImage },
      { onSuccess: () => updateUser(data, { onSuccess: () => refresh() }) },
    );
  };
  return (
    <Flex flexDirection="row" alignItems="center">
      <Avatar
        alt="User Profile"
        src={imgUrl || DefaultProfile}
        sx={{
          width: "100px",
          height: "100px",
        }}
      />
      <Button variant="contained" component="label" disabled={false}>
        프로필 수정하기
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleProfileChange}
        />
      </Button>
    </Flex>
  );
};

export default ProfileCard;
